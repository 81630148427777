import { FramerNextPages } from '@graphcommerce/framer-next-pages'
import { GraphQLProvider } from '@graphcommerce/graphql'
import { GlobalHead } from '@graphcommerce/magento-store'
import {
  CssAndFramerMotionProvider,
  DarkLightModeThemeProvider,
  PageLoadIndicator,
} from '@graphcommerce/next-ui'
import { CssBaseline } from '@mui/material'
import { AppProps } from 'next/app'
// eslint-disable-next-line import/order
import { useEffect } from 'react'
import CompareMessageProvider from '../components/Product/AddToCompareBtn/CompareMessageProvider'
import CheckCart from '../components/checkCart'
import YekanBakhFaNumReg from '../components/font'
// import { CacheProvider } from '@emotion/react'
// import createCache from '@emotion/cache'
// import rtlPlugin from 'stylis-plugin-rtl'
import { lightThemeLTR, lightThemeRTL } from '../components/theme'
import './global.css'

import {
  ModalContext,
  ModalContextValue,
  ScriptContext,
  ScriptContextValue,
} from '../context/context'
import { LoadingProvider } from '../context/loadingContext'
import WishListProvider from '../context/wishlistContext'
import { I18nProvider } from '../lib/i18n/I18nProvider'

// const cacheRtl = createCache({
//   key: 'muirtl',
//   stylisPlugins: [rtlPlugin],
// })
// const cacheLtr = createCache({
//   key: 'mui',
// })

export default function ThemedApp(props: AppProps) {
  // const { locale = 'fa' } = useRouter()
  
  const locale = 'fa'

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.dir = locale.includes('fa') ? 'rtl' : 'ltr'
    }
  }, [locale])

  return (
    <CssAndFramerMotionProvider>
      <I18nProvider key={locale} locale={locale}>
        <GraphQLProvider {...props}>
          <ModalContext.Provider value={ModalContextValue()}>
            <CompareMessageProvider>
              <WishListProvider>
                <LoadingProvider>
                  <ScriptContext.Provider value={ScriptContextValue()}>
                    <DarkLightModeThemeProvider
                      light={locale.includes('fa') ? lightThemeRTL : lightThemeLTR}
                      dark={locale.includes('fa') ? lightThemeRTL : lightThemeLTR}
                    >
                      <div className={YekanBakhFaNumReg.className}>
                        <GlobalHead />
                        <CssBaseline />
                        <PageLoadIndicator />
                        <CheckCart>
                          <FramerNextPages {...props} />
                        </CheckCart>
                      </div>
                    </DarkLightModeThemeProvider>
                  </ScriptContext.Provider>
                </LoadingProvider>
              </WishListProvider>
            </CompareMessageProvider>
          </ModalContext.Provider>
        </GraphQLProvider>
      </I18nProvider>
    </CssAndFramerMotionProvider>
  )
}
